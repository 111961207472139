var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","align":"stretch"}},[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$lang("applicationManager.jurisdiction.title"))+" ")]),_c('v-data-table',{class:{ 'row-pointer': _vm.hasEditAccess },attrs:{"headers":_vm.headers,"items":_vm.jurisdictions,"search":_vm.search,"loading":_vm.isLoading,"expanded":_vm.expanded,"show-expand":"","single-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.handleClickByTableRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$lang('components.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),(_vm.hasCreateAccess)?_c('v-btn',{staticClass:"mx-4",attrs:{"color":"primary","small":"","fab":""},on:{"click":function($event){return _vm.$router.push({
                    name: 'jurisdiction_new',
                  })}}},[_c('v-icon',[_vm._v("add")])],1):_vm._e()],1)]},proxy:true},{key:"item.data-table-expand",fn:function(ref){
                  var item = ref.item;
                  var isExpanded = ref.isExpanded;
                  var expand = ref.expand;
return [(_vm.canExpand(item))?_c('v-btn',{attrs:{"text":"","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return expand(!isExpanded)}}},[_c('v-icon',{style:(isExpanded
                    ? 'transform: rotate(90deg);'
                    : 'transform: rotate(0deg);')},[_vm._v("mdi-chevron-right")])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
                    var headers = ref.headers;
                    var item = ref.item;
return [(item.values.length)?_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-0 rounded-0",attrs:{"headers":_vm.expandedHeaders,"items":item.values,"tile":"","hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                    var item = ref.item;
return [_c('tr',[_c('td',{staticStyle:{"min-width":"185px"}}),_c('td',{staticStyle:{"min-width":"200px"}},[_c('b',[_vm._v(_vm._s(item.name))])]),_c('td',{staticStyle:{"min-width":"200px"}},[_vm._v(" "+_vm._s(item.value)+" ")])])]}}],null,true)})],1):_vm._e()]}},{key:"item.name",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column py-1"},[_c('span',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('span',{staticClass:"text-body-2 grey--text"},[_vm._v(" "+_vm._s(item.description)+" ")])])]}},{key:"item.values",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$lang("applicationManager.values"))+": "+_vm._s(item.values.length)+" ")])]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleItemDeleting(item.id)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }