




































































































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import JurisdictionResponseModel from "@/application-manager/models/JurisdictionResponseModel";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";

@Component
export default class JurisdictionsView extends mixins(AppSectionAccessMixin) {
  search = "";
  expanded = [];

  readonly expandedHeaders = [
    { text: "", value: "" },
    {
      text: "",
      align: "start",
      value: "name",
      sortable: false,
      width: "30%",
    },
    {
      text: "",
      align: "start",
      value: "value",
      sortable: false,
      width: "60%",
    },
  ];

  get isLoading(): boolean {
    return this.$store.state.jurisdictionStore.isLoadingJurisdictions;
  }

  get jurisdictions(): Array<JurisdictionResponseModel> {
    return this.$store.state.jurisdictionStore.jurisdictions;
  }

  get headers(): Array<Record<string, any>> {
    return [
      { text: "", value: "data-table-expand" },
      {
        text: this.$lang(`applicationManager.name`),
        align: "start",
        value: "name",
        sortable: false,
        width: "25%",
      },
      {
        text: this.$lang(`applicationManager.values`),
        align: "start",
        value: "values",
        sortable: false,
        width: "500px",
      },
      ...(this.hasDeleteAccess
        ? [
            {
              text: this.$lang("jsonEditor.actions"),
              value: "actions",
              align: "end",
              sortable: false,
              width: "100px",
            },
          ]
        : []),
    ];
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("applicationManager.jurisdiction.title")
    );
  }

  async mounted() {
    await this.$store.dispatch("loadJurisdictions");
  }

  canExpand(item: JurisdictionResponseModel): boolean {
    return !!item.values.length;
  }

  handleClickByTableRow(item: JurisdictionResponseModel) {
    if (!this.hasEditAccess) {
      return;
    }

    this.$router.push({
      name: "jurisdiction_view",
      params: {
        jurisdictionId: String(item.id),
      },
    });
  }

  async handleItemDeleting(jurisdictionId: number) {
    await this.$store.dispatch("deleteJurisdiction", jurisdictionId);
  }
}
